import { createSlice } from "@reduxjs/toolkit";
import {
  getCategories,
  getUrlShortenerData,
  getUrlShortenersForClientPortal,
  getUrlSlug,
  getWorkspaceContacts,
  postNewUrlShortener,
  addCategories,
  bulkEditPortal,
  searchUrl,
} from "../Thunks/urlRetargetingAction";
import { getContactUrlShortenerData } from "../Thunks/contact/contactUrlRetargetingAction";

const initialState = {
  urlSlug: "",
  categories: [],
  isContactLoading: false,
  urlShortenerListing: {
    projects: [],
    page_data: {
      first_page_url: null,
      last_page_url: null,
      next_page_url: null,
      prev_page_url: null,
      page_url: null,
      total_data: 0,
      per_page: 10,
      current_page: 1,
      from: 1,
      to: 0,
    },
    total_projects: 0,
    records_per_page: 10,
  },
  clientUrlShortenerListing: {
    projects: [],
    paginationData: {},
  },
  portalUrlShortenerListing: [],
  urlShortenerAnalytics: {},
  analyticsInfo: {},
  utmDefaultValue: {},
  utmParams: {},
  ogValues: {},
  gdprValues: {},
  abRotatorValues: {},
  deepLinkingValue: false,
  pagination: {
    first_page_url: null,
    last_page_url: null,
    next_page_url: null,
    prev_page_url: null,
    page_url: null,
    total_data: 0,
    per_page: 10,
    current_page: 1,
    from: 1,
    to: 0,
  },
  workspaceContacts: [],
  geoLocationValues: {},
  isLoading: false,
  isListingLoading: false,
  error: null,
  getUrlShortenerDataApi: {
    status: "idle",
    error: null,
  },
};

const urlRetargetingSlice = createSlice({
  name: "urlRetargeting",
  initialState,
  reducers: {
    resetState(state) {
      Object.assign(state, initialState);
    },
    setUrlSlugState(state, action) {
      state.urlSlug = action.payload;
    },
    setUrlShortenerState(state, action) {
      state.urlShortenerListing = action.payload;
    },
    // setPortalUrlShortenerState(state, action) {
    // 	state.portalUrlShortenerListing = action.payload;
    // },
    addUrlShortnerState(state, action) {
      state.urlShortenerListing.page_data = action.payload.page_data;
      state.urlShortenerListing.projects.push(action.payload);
    },
    setUrlShortnerAnalyticsState(state, action) {
      state.urlShortenerAnalytics = action.payload;
    },
    setAnalyticsInfo(state, action) {
      state.analyticsInfo = action.payload;
    },
    setCategories(state, action) {
      state.categories = action.payload;
    },

    setUtmDefaultState(state, action) {
      state.utmDefaultValue = action.payload;
    },
    setUtmParametersState(state, action) {
      state.utmParams = action.payload;
    },
    setOgState(state, action) {
      state.ogValues = action.payload;
    },
    setGdprState(state, action) {
      state.gdprValues = action.payload;
    },
    setAbRotatorState(state, action) {
      state.abRotatorValues = action.payload;
    },
    deleteAbRotatorState(state, action) {
      state.deleteAbRotatorState = state.abRotatorValues.filter(
        (data) => data.id !== action.payload
      );
    },

    addNewAbField(state, action) {
      state.abRotatorValues.push(action.payload);
    },
    setDeepLinkState(state, action) {
      state.deepLinkingValue = action.payload;
    },
    setUrlShortenerPagination(state, action) {
      state.pagination = action.payload;
    },
    setGeoLocationState(state, action) {
      state.geoLocationValues = action.payload;
    },
    deleteGeoLocationState(state, action) {
      state.geoLocationValues = state.geoLocationValues.filter(
        (data) => data.id !== action.payload
      );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUrlSlug.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUrlSlug.fulfilled, (state, action) => {
        state.isLoading = false;
        state.urlSlug = action.payload;
      })
      .addCase(getUrlSlug.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getUrlShortenerData.pending, (state, action) => {
        if (state.getUrlShortenerDataApi.status === "idle") {
          // state.isLoading = true;
          state.getUrlShortenerDataApi.status = "pending";
        }
        state.isListingLoading = true;
      })
      .addCase(getUrlShortenerData.fulfilled, (state, action) => {
        if (state.getUrlShortenerDataApi.status === "pending") {
          state.urlShortenerListing = action.payload;
          state.pagination = action.payload.page_data;
          state.getUrlShortenerDataApi.status = "idle";
          state.isListingLoading = false;
        }
      })
      .addCase(getUrlShortenerData.rejected, (state, action) => {
        if (state.getUrlShortenerDataApi.status === "pending") {
          // state.isLoading = false;
          state.getUrlShortenerDataApi.error = action.error;
          state.getUrlShortenerDataApi.status = "idle";
          state.isListingLoading = false;
        }
      })
      .addCase(getCategories.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories = action.payload;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getWorkspaceContacts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getWorkspaceContacts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.workspaceContacts = action.payload;
      })
      .addCase(getWorkspaceContacts.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(addCategories.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addCategories.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addCategories.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(bulkEditPortal.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(bulkEditPortal.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(bulkEditPortal.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(searchUrl.pending, (state, action) => {
        state.isLoading = true;
        state.isListingLoading = true;
      })
      .addCase(searchUrl.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isListingLoading = false;

        state.urlShortenerListing = action.payload.data.data;
      })
      .addCase(searchUrl.rejected, (state, action) => {
        state.isLoading = false;
        state.isListingLoading = false;
        state.error = action.error;
      })
      .addCase(getContactUrlShortenerData.pending, (state, action) => {
        state.isLoading = true;
        state.isContactLoading = true;
      })
      .addCase(getContactUrlShortenerData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isContactLoading = false;
        state.clientUrlShortenerListing.paginationData =
          action.payload.page_data;
        state.clientUrlShortenerListing.projects = action.payload.projects;
      })
      .addCase(getContactUrlShortenerData.rejected, (state, action) => {
        state.isContactLoading = false;
        state.isLoading = false;
      });
  },
});

export const {
  resetState,
  setUrlSlugState,
  setUrlShortenerState,
  setPortalUrlShortenerState,
  addUrlShortnerState,
  setUrlShortnerAnalyticsState,
  setAnalyticsInfo,
  setCategories,
  setUtmDefaultState,
  setUtmParametersState,
  setOgState,
  setGdprState,
  setAbRotatorState,
  deleteAbRotatorState,
  setDeepLinkState,
  setUrlShortenerPagination,
  setGeoLocationState,
  deleteGeoLocationState,
} = urlRetargetingSlice.actions;

export const urlRetargetingReducer = urlRetargetingSlice.reducer;
