import axios from "axios";
import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "@/http/axiosInterceptor";
import { setCategories } from "../Slice/urlRetargetingSlice";

const API_URL = process.env.NEXT_PUBLIC_BASE_URL;

export const searchUrl = createAsyncThunk(
  "urlRetargeting/search",
  async (term, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/url-shortener/projects?page=1&search=${term}&records_per_page=`
      );
      return { data: response.data, headers: {} };
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const checkDeepLinkUrl = createAsyncThunk(
  "urlRetargeting/checkDeepLinkUrl",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/url-shortener/check-deeplink-url?long_url=${arg}`
      );

      return response.data?.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUrlSlug = createAsyncThunk(
  "urlRetargeting/getUrlSlug",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/url-shortener/slug`);
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const downloadCSVTemplate = createAsyncThunk(
  "urlRetargeting/downloadCSVTemplate",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/api/url-shortener/download-csv");
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const allowBulkUpload = createAsyncThunk(
  "urlRetargeting/allowBulkUpload",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/api/url-shortener/allow-bulk-upload");
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addCategories = createAsyncThunk(
  "urlRetargeting/addCategories",
  async (data, { rejectWithValue, commit }) => {
    try {
      const response = await Api.post("/api/url-shortener/tag", {
        tags: { tag_name: data },
      });
      setCategories();
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const bulkEdit = createAsyncThunk(
  "urlRetargeting/bulkEdit",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.put("/api/url-shortener/project", {
        data: data,
      });
      await dispatch("getUrlShortenerData");
      dispatch("getUrlSlug");
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const bulkEditPortal = createAsyncThunk(
  "urlRetargetingPortal/bulkEditPortal",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.put("/api/url-shortener/project", {
        data: data,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCategories = createAsyncThunk(
  "urlRetargetingPortal/getCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/url-shortener/tags`);
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getWorkspaceContacts = createAsyncThunk(
  "urlRetargetingPortal/getWorkspaceContacts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/contacts/all`);
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// export const getUrlShortenerData = createAsyncThunk(
//   "urlRetargeting/getUrlShortenerData",
//   async (arg, { getState, rejectWithValue }) => {
//     const data = _.isUndefined(arg) ? { page: 1, searchTerm: "" } : arg;
//     try {
//       const response = await Api.get(
//         `${API_URL}/api/url-shortener/projects?page=` +
//           data.page +
//           "&search=" +
//           data.searchTerm +
//           "&records_per_page=" +
//           (data.recordsPerPage ? data.recordsPerPage : "") +
//           (data.clientId ? "&client_id=" + data.clientId : "")
//       );
//       return response.data.data;
//     } catch (error) {
//       if (error.response) {
//         return rejectWithValue(error.response.data);
//       } else {
//         return rejectWithValue(error.message);
//       }
//     }
//   }
// );

export const getUrlShortenerData = createAsyncThunk(
  "urlRetargeting/getUrlShortenerData",
  async (arg = { page: 1, searchTerm: "" }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      params.append("page", arg?.page || 1);
      params.append("search", arg?.searchTerm || "");
      if (arg.records_per_page)
        params.append("records_per_page", arg.records_per_page);
      if (arg.clientId) params.append("client_id", arg.clientId);

      const response = await Api.get(
        `${API_URL}/api/url-shortener/projects?${params.toString()}`
      );

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const postNewUrlShortener = createAsyncThunk(
  "urlRetargeting/postNewUrlShortener",
  async (arg, { getState, rejectWithValue, dispatch }) => {
    const sentData = _.isUndefined(arg) ? { page: 1, searchTerm: "" } : arg;

    const { authStrategy } = getState().auth;
    try {
      const response = await Api.post(`${API_URL}/api/url-shortener/project`, {
        project: sentData,
      });

      if (authStrategy === "local") {
        dispatch(getUrlShortenerData());
      } else {
        dispatch(getUrlShortenersForClientPortal());
      }

      if (response.data) {
        dispatch(getUrlSlug());
        // dispatch(createQRCode(response.data?.data?.id));
      }

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const createQRCode = createAsyncThunk(
  "urlRetargeting/createQRCode",
  async (arg, { getState, rejectWithValue }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/qr-code/urlshortener/${arg}`
      );

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
