import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "@/http/axiosInterceptor";

const API_URL = process.env.NEXT_PUBLIC_BASE_URL;

export const getContactUrlShortenerData = createAsyncThunk(
  "urlRetargeting/getContactUrlShortenerData",
  async (arg, { getState, rejectWithValue }) => {
    const data = _.isUndefined(arg) ? { page: 1, searchTerm: "" } : arg;

    try {
      const response = await Api.get(
        `${API_URL}/api/url-shortener/projects?page=` +
          data.page +
          "&search=" +
          data.searchTerm +
          "&records_per_page=" +
          (data.recordsPerPage ? data.recordsPerPage : "") +
          (data.clientId ? "&client_id=" + data.clientId : "")
      );



      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
