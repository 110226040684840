import axios from "axios";
import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "@/http/axiosInterceptor";
import { setCategories } from "../Slice/urlRetargetingSlice";
import { getUrlSlug } from "./urlRetargetingAction";

const API_URL = process.env.NEXT_PUBLIC_BASE_URL;

export const checkDeepLinkUrlForPortal = createAsyncThunk(
  "urlRetargetingPortal/checkDeepLinkUrlForPortal",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/url-shortener/check-deeplink-url?long_url=${arg}`
      );
      return response.data?.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addCategories = createAsyncThunk(
  "urlRetargetingPortal/addCategories",
  async (data, { commit, rejectWithValue }) => {
    try {
      const response = await Api.post("/api/url-shortener/tag", {
        tags: { tag_name: data },
      });
      setCategories();
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const bulkEditPortal = createAsyncThunk(
  "urlRetargetingPortal/bulkEditPortal",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.put("/api/url-shortener/project", {
        data: data,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUrlSlugForPortal = createAsyncThunk(
  "urlRetargetingPortal/getUrlSlugForPortal",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/clientportal/url-shortener/slug`
      );
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// export const getUrlShortenersForClientPortal = createAsyncThunk(
//   "urlRetargetingPortal/getUrlShortenersForClientPortal",
//   async (arg, { rejectWithValue }) => {
//     const data = _.isUndefined(arg) ? { page: 1, searchTerm: "" } : arg;

//     try {
//       const response = await Api.get(
//         `${API_URL}/api/url-shortener/projects?is_portal=true&page=` +
//           data.page +
//           "&search=" +
//           data.searchTerm +
//           "&records_per_page=" +
//           (data.recordsPerPage ? data.recordsPerPage : "") +
//           (data.clientId ? "&client_id=" + data.clientId : "")
//       );
//       return response.data.data;
//     } catch (error) {
//       if (error.response) {
//         return rejectWithValue(error.response.data);
//       } else {
//         return rejectWithValue(error.message);
//       }
//     }
//   }
// );
export const getUrlShortenersForClientPortal = createAsyncThunk(
  "urlRetargetingPortal/getUrlShortenersForClientPortal",
  async (arg = { page: 1, searchTerm: "" }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      params.append("page", arg?.page || 1);
      params.append("is_portal", true);
      params.append("search", arg?.searchTerm || "");
      if (arg.records_per_page)
        params.append("records_per_page", arg.records_per_page);
      if (arg.clientId) params.append("client_id", arg.clientId);

      const response = await Api.get(
        `${API_URL}/api/url-shortener/projects?${params.toString()}`
      );

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const postNewUrlShortenerForPortal = createAsyncThunk(
  "urlRetargetingPortal/postNewUrlShortenerForPortal",
  async (arg, { getState, rejectWithValue, dispatch }) => {
    const sentData = _.isUndefined(arg) ? { page: 1, searchTerm: "" } : arg;

    try {
      const response = await Api.post(
        `${API_URL}/api/clientportal/url-shortener/project`,
        {
          project: sentData,
        }
      );

      dispatch(getUrlShortenersForClientPortal());
      if (response.data) {
        dispatch(getUrlSlug());

        // dispatch(createQRCode(response?.data?.data?.id));
      }

      return response?.data?.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
const createQRCode = createAsyncThunk(
  "urlRetargeting/createQRCode",
  async (arg, { getState, rejectWithValue }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/qr-code/urlshortener/${arg}`
      );

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const searchUrl = createAsyncThunk(
  "urlRetargetingPortal/search",
  async (term, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/url-shortener/projects?page=1&search=${term}&records_per_page=`
      );
      return { data: response.data, headers: {} };
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const removeLink = createAsyncThunk(
  "urlRetargetingPortal/removeLink",
  async (selectedRows, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.delete(
        `${API_URL}/api/url-shortener/project`,
        {
          data: { project_ids: selectedRows },
        }
      );

      if (response.data.data == true) {
        return selectedRows;
      }
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCategories = createAsyncThunk(
  "urlRetargetingPortal/getCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/url-shortener/tags`);
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getWorkspaceContacts = createAsyncThunk(
  "urlRetargetingPortal/getWorkspaceContacts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/contacts/all`);

      return response?.data?.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAnalyticsData = createAsyncThunk(
  "urlRetargetingPortal/getAnalyticsData",
  async ({ payload, id }, { rejectWithValue, dispatch }) => {
    const params = {
      page: payload && payload.page ? payload.page : 1,
      startDate: payload && payload.start ? payload.start : "",
      endDate: payload && payload.end ? payload.end : "",
    };
    try {
      const response = await Api.get(
        `${API_URL}/api/analytics/urlRetargeting/${id}`,
        { params }
      );
      return response?.data?.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
